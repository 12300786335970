::-webkit-scrollbar {
  @apply w-2;
}

::-webkit-scrollbar-track {
  @apply bg-white;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}

.dark ::-webkit-scrollbar-track,
.dark::-webkit-scrollbar-track {
  @apply bg-gray-800;
}

.dark ::-webkit-scrollbar-thumb,
.dark::-webkit-scrollbar-thumb {
  @apply bg-gray-600;
}

.dark ::-webkit-scrollbar-thumb:hover,
.dark::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500;
}
