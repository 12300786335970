[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}
.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1;
}
.tippy-box[data-placement^="top"] > .tippy-backdrop {
  transform-origin: 0 25%;
  border-radius: 40% 40% 0 0;
}
.tippy-box[data-placement^="top"] > .tippy-backdrop[data-state="visible"] {
  transform: scale(1) translate(-50%, -55%);
}
.tippy-box[data-placement^="top"] > .tippy-backdrop[data-state="hidden"] {
  transform: scale(0.2) translate(-50%, -45%);
}
.tippy-box[data-placement^="bottom"] > .tippy-backdrop {
  transform-origin: 0 -50%;
  border-radius: 0 0 30% 30%;
}
.tippy-box[data-placement^="bottom"] > .tippy-backdrop[data-state="visible"] {
  transform: scale(1) translate(-50%, -45%);
}
.tippy-box[data-placement^="bottom"] > .tippy-backdrop[data-state="hidden"] {
  transform: scale(0.2) translate(-50%);
}
.tippy-box[data-placement^="left"] > .tippy-backdrop {
  transform-origin: 50% 0;
  border-radius: 50% 0 0 50%;
}
.tippy-box[data-placement^="left"] > .tippy-backdrop[data-state="visible"] {
  transform: scale(1) translate(-50%, -50%);
}
.tippy-box[data-placement^="left"] > .tippy-backdrop[data-state="hidden"] {
  transform: scale(0.2) translate(-75%, -50%);
}
.tippy-box[data-placement^="right"] > .tippy-backdrop {
  transform-origin: -50% 0;
  border-radius: 0 50% 50% 0;
}
.tippy-box[data-placement^="right"] > .tippy-backdrop[data-state="visible"] {
  transform: scale(1) translate(-50%, -50%);
}
.tippy-box[data-placement^="right"] > .tippy-backdrop[data-state="hidden"] {
  transform: scale(0.2) translate(-25%, -50%);
}
.tippy-box[data-animatefill] {
  background-color: initial !important;
}
.tippy-backdrop {
  position: absolute;
  background-color: #333;
  border-radius: 50%;
  width: calc(110% + 32px);
  left: 50%;
  top: 50%;
  z-index: -1;
  transition: all cubic-bezier(0.46, 0.1, 0.52, 0.98);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.tippy-backdrop[data-state="hidden"] {
  opacity: 0;
}
.tippy-backdrop:after {
  content: "";
  float: left;
  padding-top: 100%;
}
.tippy-backdrop + .tippy-content {
  transition-property: opacity;
  will-change: opacity;
}
.tippy-backdrop + .tippy-content[data-state="hidden"] {
  opacity: 0;
}
.tippy-box[data-placement^="top"] > .tippy-svg-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-svg-arrow:after,
.tippy-box[data-placement^="top"] > .tippy-svg-arrow > svg {
  top: 16px;
  transform: rotate(180deg);
}
.tippy-box[data-placement^="bottom"] > .tippy-svg-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-svg-arrow > svg {
  bottom: 16px;
}
.tippy-box[data-placement^="left"] > .tippy-svg-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-svg-arrow:after,
.tippy-box[data-placement^="left"] > .tippy-svg-arrow > svg {
  transform: rotate(90deg);
  top: calc(50% - 3px);
  left: 11px;
}
.tippy-box[data-placement^="right"] > .tippy-svg-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-svg-arrow:after,
.tippy-box[data-placement^="right"] > .tippy-svg-arrow > svg {
  transform: rotate(-90deg);
  top: calc(50% - 3px);
  right: 11px;
}
.tippy-svg-arrow {
  width: 16px;
  height: 16px;
  fill: #333;
  text-align: initial;
}
.tippy-svg-arrow,
.tippy-svg-arrow > svg {
  position: absolute;
}
.tippy-box[data-animation="shift-away"][data-state="hidden"] {
  opacity: 0;
}
.tippy-box[data-animation="shift-away"][data-state="hidden"][data-placement^="top"] {
  transform: translateY(10px);
}
.tippy-box[data-animation="shift-away"][data-state="hidden"][data-placement^="bottom"] {
  transform: translateY(-10px);
}
.tippy-box[data-animation="shift-away"][data-state="hidden"][data-placement^="left"] {
  transform: translateX(10px);
}
.tippy-box[data-animation="shift-away"][data-state="hidden"][data-placement^="right"] {
  transform: translateX(-10px);
}
.tippy-box[data-animation="shift-away-subtle"][data-state="hidden"] {
  opacity: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  width: 16px;
  height: 16px;
  color: currentColor;
}
.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
