@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@import "./react-grid-layout.css";
@import "./react-vitualized.css";
@import "./react-charts.css";
@import "./tippyjs.css";
@import "./inline-edit.css";
@import "./svg-preview.css";
@import "./scrollbar.css";
@import "./scrollbox.css";

html {
  @apply overflow-y-scroll font-sans bg-indigo-800;
}

* {
  @apply outline-none;
}

html,
body,
#__next {
  @apply w-full h-full;
}

.noTransition * {
  @apply transition-none;
}

.dark:root {
  color-scheme: dark;
}

.dark input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #4b5563 inset !important;
  background-color: transparent !important;
}

::selection {
  @apply bg-indigo-600 dark:bg-indigo-800 text-white;
}

textarea,
input {
  @apply focus-visible:!ring-indigo-500 focus-visible:!border-indigo-500 focus-visible:!ring-1;
}

.regexHighlight {
  @apply !text-white bg-indigo-600 p-0.5;
}
