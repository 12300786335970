.svgPreview {
  background-image: linear-gradient(45deg, #eee 25%, transparent 25%),
    linear-gradient(-45deg, #eee 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #eee 75%),
    linear-gradient(-45deg, transparent 75%, #eee 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
.dark .svgPreview {
  background-image: linear-gradient(45deg, #111827 25%, transparent 25%),
    linear-gradient(-45deg, #111827 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #111827 75%),
    linear-gradient(-45deg, transparent 75%, #111827 75%);
}
.svgPreview::after {
  @apply content-[""] pointer-events-none absolute inset-0 border-2 border-indigo-500 opacity-0 rounded-md lg:rounded-r-none lg:rounded-t-none transition-opacity;
}
.svgPreview:hover::after {
  @apply opacity-100;
}
.svgPreview svg {
  max-width: 360px;
  max-height: 360px;
  min-width: 24px;
  min-height: 24px;
}
