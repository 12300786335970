.inline-edit {
  @apply relative items-center align-top inline-grid;
}

.inline-edit::after,
.inline-edit input {
  grid-area: 1 / 2;
  font: inherit;
  @apply border-l-0 border-r-0 border-t-0 border-b-2 border-transparent bg-transparent;
}

.inline-edit::after {
  content: attr(data-value);
  grid-area: 1 / 2;
  @apply invisible;
}

.inline-edit input {
  @apply focus:!ring-0 p-0 border-gray-200 dark:border-gray-700 dark:hover:border-gray-500 focus:border-indigo-400 dark:focus:border-indigo-400;
}

.inline-edit input[disabled] {
  @apply !border-transparent cursor-text;
}
